import React, { useState } from "react";
import "./App.css";
import ThemeProvider from "./theme";
import dashboardLogo from "./images/dashboard.png";
import girl from "./images/girl.jpg";
import speech_bubbles_white from "./images/speech-bubbles_White.png";
import communication_problem_white from "./images/communication-problems_White.png";
import error_message_white from "./images/error-message_White.png";
import effective_communication_Blue from "./images/effective-communication_Blue.png";
import increase_revenue_Blue from "./images/increase-revenue_Blue.png";
import secure_chat_Blue from "./images/Secure-chat_Blue.png";
import dashboard from "./images/Dashboard_info_img.png";
import report from "./images/report_img.png";
import patientInfo from "./images/Patient_info_img.png";
import footerImage from "./images/footerImage.jpg";
import InformationModal from "./modal/InformationModal";
import SnackAlert from "./modal/SnackAlert";
import redirectConfig from "./redirectConfig";

const App = () => {
  const [show, setShow] = useState(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertSeverity, setAlertSeverity] = React.useState("");

  const handleInfoModal = () => {
    setShow(!show);
  };

  return (
    <ThemeProvider>
      <div className="body">
        <div className="d-flex d-flex-wrap justify-space-between align-item-center header">
          <img src={dashboardLogo} alt="Agrippon logo" className="logo" />
          <div className="d-flex gap-24 margin-right align-item-center my-10">
            <p
              className="header-blue-button"
              // style={{
              //   backgroundColor: "#03b2e9",
              //   padding: "8px",
              //   paddingLeft: "70px",
              //   paddingRight: "70px",
              //   borderRadius: "10px",
              //   color: "white",
              //   fontWeight: "bold",
              //   margin: "0px",
              // }}
              onClick={handleInfoModal}
            >
              Ready to learn more? Schedule your consultation!
            </p>

            <a
              href={`${
                redirectConfig[process.env.REACT_APP_ENVIRONMENT]
              }/login`}
            >
              <button
                style={{
                  backgroundColor: "#434750",
                  padding: "10px 30px",
                  borderRadius: "10px",
                  color: "white",
                  fontWeight: "bold",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                LOGIN
              </button>
            </a>
          </div>
        </div>
        {/* <div className="girl" style={{ backgroundImage: `url(${girl})` }}>
          <div
            style={{ width: "100%" }}
            className="container d-flex justify-space-end"
          >
            <div className="img-text">
              <p className="img-text-1">Get a grip on your dental claims.</p>
              <p className="img-text-2">Get Agrippon</p>
            </div>
          </div>
        </div> */}
        <div
          className="girl place-end end-image"
          style={{
            backgroundImage: `url(${footerImage})`,
            position: "relative",
          }}
        >
          <div style={{ width: "100%", height: "100%" }} className="temp"></div>
          <div className="img-shadow">
            <div
              className="img-inner-contain"
              style={{ position: "relative", zIndex: 1, margin: "70px 0" }}
            >
              <h2 className="billing-title">
                Ready to increase your billing revenue?
              </h2>
              {/* <p className="end-image-text-2" onClick={handleInfoModal}>
                Ready to learn more? Schedule your consultation!
              </p> */}
            </div>
          </div>
        </div>

        <div className="problem">
          <div className="container">
            <div className="problem-inner-contain">
              <p className="problem-header">The Problem</p>
              <p className="problem-body-text">
                Dental Offices and Billers must communicate to file insurance
                claims, but...
              </p>
              <div className="problem-box">
                <div className="problem-box-1">
                  <img
                    src={speech_bubbles_white}
                    alt="message icon"
                    className="msg-icon"
                  />
                  <p className="problem-box-1-text">
                    There is{" "}
                    <span style={{ color: "#03B2E9" }}>
                      no system that tracks and analyzes
                    </span>{" "}
                    claim communication.
                  </p>
                </div>
                <div className="problem-box-1">
                  <img
                    src={communication_problem_white}
                    alt="message icon"
                    className="msg-icon"
                  />
                  <p className="problem-box-1-text">
                    Miscommunication leads to{" "}
                    <span style={{ color: "#03B2E9" }}>
                      thousands in lost revenue
                    </span>
                    .{" "}
                  </p>
                </div>
                <div className="problem-box-1">
                  <img
                    src={error_message_white}
                    alt="message icon"
                    className="msg-icon"
                  />
                  <p className="problem-box-1-text">
                    Most current methods are{" "}
                    <span style={{ color: "#03B2E9" }}>
                      not HIPAA complaint
                    </span>
                    .{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="solution-dashboard text-align-center container">
          <div className="solution-header">The Solution</div>
          <div className="solution-text-1">
            <p>
              Seamlessly connect Dental Offices and Billers with this
              revolutionary communication portal.
            </p>
            <p>
              Agrippon is optimized for DSO's and billing agencies while still
              being beneficial to the individual office.
            </p>
          </div>
          <div className="container">
            <div className="solution-inner-contain">
              <div className="solution-box">
                <div className="solution-box-1">
                  <img
                    src={effective_communication_Blue}
                    alt="message icon"
                    className="sol-icon"
                  />
                  <p className="solution-box-1-text">
                    <span style={{ color: "#03B2E9" }}>
                      Streamline, track and improve communication
                    </span>{" "}
                    between billing and clinical staff.
                  </p>
                </div>
                <div className="solution-box-1">
                  <img
                    src={increase_revenue_Blue}
                    alt="message icon"
                    className="sol-icon"
                  />
                  <p className="solution-box-1-text">
                    <span style={{ color: "#03B2E9" }}>Increase revenue</span>{" "}
                    by submitting more accurate claims.
                  </p>
                </div>
                <div className="solution-box-1">
                  <img
                    src={secure_chat_Blue}
                    alt="message icon"
                    className="sol-icon"
                  />
                  <p className="solution-box-1-text">
                    Be confident knowing you are
                    <span style={{ color: "#03B2E9" }}>
                      {" "}
                      HIPAA complaint
                    </span>{" "}
                    in all your communication.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ==== */}
        <div className="communication-dashboard bg-line">
          <div className="">
            <div className="sub-title"></div>
            <div className="container">
              <div className="row">
                <div className="col-7">
                  <img
                    src={dashboard}
                    alt="message icon"
                    className="dashboardImage"
                  />
                </div>
                <div className="col-5">
                  <div className="img-contain">
                    <h3 className="container pl-0">Communications Dashboard</h3>
                    <p>
                      Use the dashboard to get an overview of your
                      communications between the office and billing
                      organization. Each box represents types of communication
                      that you can focus on. The numbers represent the items
                      that need your attention. Close items to drive your Todo
                      list to zero and ensure you have all the information to
                      submit a claim.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----------------- */}
        <div className="img-margin-top">
          <div className="request-detail-dashboard bg-line">
            <div className="">
              <div className="sub-title"></div>
              <div className="container">
                <div className="row">
                  {/* <div className="request-detail-row"> */}
                  <div className="col-5">
                    <div className="request-detail-text img-contain">
                      <h3 className="container request-detail-header pl-0">
                        Request Detail
                      </h3>
                      <p>
                        See the details of the request you have created. See the
                        conversation history and determine the best path forward
                        to determine how to file a claim.
                      </p>
                    </div>
                  </div>
                  <div className="col-7">
                    <img
                      src={patientInfo}
                      alt="message icon"
                      className="dashboardImage2"
                    />
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------- */}

        <div className="img-margin-top">
          <div className="communication-dashboard bg-line report-dashboard">
            <div className="">
              <div className="sub-title"></div>
              <div className="container">
                <div className="row">
                  <div className="col-7">
                    <img
                      src={report}
                      alt="message icon"
                      className="dashboardImage"
                    />
                  </div>
                  <div className="col-5">
                    <div className="img-contain">
                      <h3 className="container pl-0">Reports</h3>
                      <p>
                        View request by amount to give a idea as to how much
                        money associated with the request for information and
                        unsubmitted claims. Review the number of requests over a
                        period of time to manage your employees and provide
                        timely feedback to improvements. Further segment your
                        reports by office location, requestors and more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="iframe-container">
            <div class="youtube-container mb-60">
              <iframe
                src="https://www.youtube.com/embed/Trjg6nXgIFk?autoplay=1&mute=0&loop=1&fs=1&controls=1&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=Trjg6nXgIFk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        {/* <div
          className="girl place-end end-image"
          style={{ backgroundImage: `url(${footerImage})` }}
        >
          <div className="img-shadow">
            <div className="img-inner-contain">
              <h2 className="billing-title">
                Ready to increase your billing revenue?
              </h2>
              <p className="end-image-text-2"
                  onClick={handleInfoModal}
                >
                  Ready to learn more? Schedule your consultation!
              </p>
            </div>
          </div>
        </div> */}

        <div className="footer-copyright ">
          <div className=" container">
            {/* <p> © Agrippon {new Date().getFullYear()}. All Rights Reserved.</p> */}
            <p>
              {" "}
              © 2023 Agrippon. Pro Practice Solutions Company. All Rights
              Reserved.
            </p>
          </div>
        </div>
      </div>
      <InformationModal
        open={show}
        setOpen={setShow}
        handleEvent={handleInfoModal}
        setOpenAlert={setOpenAlert}
        setMessage={setMessage}
        setAlertSeverity={setAlertSeverity}
      />
      <SnackAlert
        open={openAlert}
        setOpen={setOpenAlert}
        severity={alertSeverity}
        message={message}
        closeTime={5000}
      />
    </ThemeProvider>
  );
};

export default App;
